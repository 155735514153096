import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import orgDocProperty, { OrganisationDocumentProperty } from '@/shared/model/organisationDocumentProperty';

const logger = new Logger('edit-process-settings-dialog');

@Component({
  name: 'edit-process-settings-dialog',
  components: {},
})
export default class EditProcessSettingsDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => orgDocProperty.parse({}) })
  private value!: any;

  get isEditMode() {
    return !!this.value.id;
  }

  get title() {
    return this.isEditMode ? this.$i18n.t('title_edit') : this.$i18n.t('title_add');
  }

  private onClose() {
    this.$emit('click:close', this.value);
  }

  private async onConfirm() {
    this.$emit('click:update', this.value, true);
  }
}
