import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import organisationProcessSettings, { OrganisationProcessSettings } from '@/shared/model/organisationProcessSettings';
import { Folder } from '@/shared/model/folder';
import EditProcessSettingsDialog from './edit-process-settings-dialog/edit-process-settings-dialog.vue';
import { OdataItems } from '@/shared/model/OdataItems';
import { DocumentProperty } from '@/shared/model/documentProperty';
import organisationDocumentProperty, {
  OrganisationDocumentProperty,
} from '@/shared/model/organisationDocumentProperty';

const name = 'operations-settings-view';
const logger = new Logger(name);
const folderModule = namespace('folder');
const orgDocumentPropertyModule = namespace('organisationDocumentProperty');

@Component({ name: name, components: { EditProcessSettingsDialog } })
export default class OrganizationSettingsView extends Vue {
  @folderModule.Action('getMenuFolders')
  private getMenuFolders!: any;

  @orgDocumentPropertyModule.Getter('getOrganisationDocumentPropertys')
  private organisationDocumentPropertys!: OdataItems<OrganisationDocumentProperty>;

  private processSettings: OrganisationProcessSettings = organisationProcessSettings.parse({});

  menu = false;
  selected: any[] = [];
  get empty() {
    return {
      id: '',
      name: `${this.$t('no_folder')}`,
      children: undefined,
      fullPath: `${this.$t('no_folder')}`,
    };
  }

  items: {
    folder?: Folder;
    id: string;
    name: string;
    children?: any[];
  }[] = [];

  @folderModule.Getter('getMenuFoldersTreeView')
  private folders!: {
    folder: Folder;
    id: string;
    name: string;
    count: undefined;
    children: any[];
    isBuiltIn: boolean;
  }[];

  @Watch('selected', { deep: true })
  private onSelected(nVal: any, oVal: any) {
    if (nVal != oVal) this.menu = false;
  }

  get emptyInfoField() {
    return organisationDocumentProperty.parse({
      fieldId: '',
      name: `${this.$t('no_info_field')}`,
    });
  }

  private getFields: any = [];

  async created() {
    await this.getMenuFolders().then(() => {
      this.items = [];
      this.items.push(this.empty);
      this.folders.forEach((element) => {
        this.items.push(element);
      });
    });
    this.organisationDocumentPropertys.items.forEach((element: OrganisationDocumentProperty) => {
      this.getFields.push(this.emptyInfoField);
      this.getFields.push(element);
    });
  }

  onChangeProcessesFolderId() {}

  onAdd() {}

  editDialog = {
    show: false,
    model: { ...organisationProcessSettings.parse({}) },
  };

  async dialogOnUpdate(item: any, isFromEdit: boolean) {
    if (isFromEdit) {
    }
  }

  private async openEditDialog(property: any) {
    this.editDialog.model = { ...property };
    this.editDialog.show = true;
  }

  async dialogOnClose() {
    this.editDialog.show = false;
  }

  //region info field
}
